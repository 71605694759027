
import { mapGetters } from 'vuex'
import adultCheck from '../../mixins/adultCheck'
import seoFaqMixin from './seo_faq'
import seoPopularProducts from './seo_popular_products'
import { PER_PAGE, BANNERS_PRODUCTS_LIST, SEARCH_QUERIES } from '@/constants'
import pageViewGTM from '@/mixins/pageViewGTM'
import productFilters from '@/mixins/productFilters'
import setDataLayer from '@/mixins/setDataLayer'
import {
  rtbAnalytics,
  setViewSearchResultsSetup,
  filterQueryWhiteList,
  decodeQuery,
  normalizeMPCategories,
} from '~/utilities'
import { mapWritableState, mapActions, mapState, storeToRefs } from 'pinia'
import { useCustomer } from '~/stores/customer'
import { usePromoCodeStore } from '~/stores/promocode'
import { useLotteryStore } from '~/stores/lottery'
import { useFeatureMGMStore } from '~/stores/feature-mgm'
import { useBloomreachStore } from '~/stores/bloomreach'

import { useMarketStore } from '@/stores/market'
import { useUiStore } from '@/stores/ui'

function initBanners(ENVIRONMENT, categoryId) {
  setTimeout(() => {
    ;(window.globalAmlAds = window.globalAmlAds || []).push(
      BANNERS_PRODUCTS_LIST[ENVIRONMENT].init_banner(categoryId),
    )
  }, 1000)
}

export default {
  name: 'MPCatalogPageSingle',
  mixins: [
    pageViewGTM,
    seoFaqMixin,
    productFilters,
    setDataLayer,
    adultCheck,
    seoPopularProducts,
  ],
  beforeRouteLeave(to, from, next) {
    const nextLocation = to?.name.replace(`___${this.$i18n.locale}`)
    if (!nextLocation.includes('product-slug')) {
      this.marketStore.breadcrumbs = []
    }
    next()
  },
  layout: 'market',
  validate({ params, redirect }) {
    const strcompare = params.slug?.toLowerCase()
    if (strcompare !== params.slug) {
      redirect(301, { path: strcompare.trim() })
    } else {
      return /^[0-9]+-(.)+/.test(params.slug)
    }
  },
  setup() {
    const PromoCodeStore = usePromoCodeStore()
    const LotteryStore = useLotteryStore()
    const marketStore = useMarketStore()
    const { productFilters } = storeToRefs(marketStore)
    const bloomreachStore = useBloomreachStore()
    return {
      PromoCodeStore,
      LotteryStore,
      bloomreachStore,
      marketStore,
      productFilters,
    }
  },
  async asyncData({ query, params, error, app }) {
    const marketStore = useMarketStore()
    const uiStore = useUiStore()
    if (process.browser) {
      marketStore.loadingProducts = true
    }
    if (!process.browser && query) {
      marketStore.productFilters = query
    }
    let category
    let view_mode
    let is_adult = false
    const slug = params.slug
    const matches = params.slug.match(/^([0-9]*)-(.*)/)
    const categoryId = matches && matches[1]

    try {
      marketStore.selfCategory.data = await marketStore.getCategoryById(
        categoryId,
      )
      view_mode = marketStore?.selfCategory?.data?.view_mode || 'categories'
      if (view_mode !== 'products') {
        const categories = await marketStore.getSubCategoryById(categoryId)
        marketStore.categories = normalizeMPCategories(categories)
      }
      marketStore.currentCategoryName =
        marketStore?.selfCategory?.data?.name || ''
      if (view_mode !== 'categories') {
        const page = query?.page || 1
        const config = {
          page,
          filters: true,
          facets: view_mode === 'products',
        }
        let options = {
          page,
          category_id: categoryId,
          per_page: uiStore.fromBB ? 8 : PER_PAGE,
        }
        if (view_mode === 'products_and_categories') {
          if (query.sort) {
            options.sort = query.sort
          } else {
            options.sort = 'global_popular_score'
          }
        } else if (query.sort) {
          options.sort = query.sort
        } else {
          options.sort = 'category_popular_score'
        }
        const queryParams = query
        Object.keys(query).forEach(key => {
          queryParams[key] = Array.isArray(query?.[key])
            ? query?.[key]?.[0]
            : query?.[key]
        })
        options = {
          ...decodeQuery(filterQueryWhiteList(queryParams)),
          ...options,
        }
        await marketStore.getProducts({ options, config })
        marketStore.moreProductOptions = { options }
      }
      const featureMGMStore = useFeatureMGMStore()
      await featureMGMStore.getFeatures()

      await marketStore.getCategorySeoData(categoryId)
      marketStore.seoFaqData = await marketStore.getSeoFaq({
        categoryId,
      })
      await marketStore.getPopularNow(categoryId)

      is_adult = category?.self?.data?.is_adult || false
      if (
        slug !==
        `${marketStore.selfCategory?.data?.id}-${
          marketStore.selfCategory?.data?.[`slugged_name_${app.$i18n.locale}`]
        }`
      ) {
        error({ statusCode: 404, message: '' })
      }
    } catch (e) {
    } finally {
      marketStore.loadingProducts = false
    }
    return {
      view_mode,
      is_adult,
      categoryId,
    }
  },
  data() {
    return {
      source: 'item_list',
    }
  },
  // async fetch() {
  //   const query = this.$route.query
  //   if (process.browser) {
  //     this.marketStore.loadingProducts = true
  //   }
  //   if (!process.browser && query) {
  //     this.marketStore.productFilters = query
  //   }
  //   if (query.response_mode) {
  //     query['p[response_mode]'] = query.response_mode
  //     delete query.response_mode
  //   }
  //   let category
  //   const slug = this.$route.params.slug
  //   const matches = this.$route.params.slug.match(/^([0-9]*)-(.*)/)
  //   const categoryId = matches && matches[1]
  //
  //   try {
  //     this.marketStore.selfCategory.data =
  //       await this.marketStore.getCategoryById(categoryId)
  //     this.view_mode =
  //       this.marketStore?.selfCategory?.data?.view_mode || 'categories'
  //     this.marketStore.currentCategoryName =
  //       this.marketStore?.selfCategory?.data?.name || ''
  //     if (this.view_mode !== 'categories') {
  //       await this.loadProducts()
  //     }
  //     const featureMGMStore = useFeatureMGMStore()
  //     await featureMGMStore.getFeatures()
  //
  //     if (this.view_mode !== 'products') {
  //       const categories = await this.marketStore.getSubCategoryById(categoryId)
  //       this.categories = normalizeMPCategories(categories)
  //     }
  //
  //     await this.marketStore.getCategorySeoData(categoryId)
  //     this.marketStore.seoFaqData = await this.marketStore.getSeoFaq({
  //       categoryId,
  //     })
  //     await this.marketStore.getPopularNow(categoryId)
  //
  //     this.is_adult = category?.self?.data?.is_adult || false
  //     if (
  //       slug !==
  //       `${this.marketStore.selfCategory?.data?.id}-${
  //         this.marketStore.selfCategory?.data?.[
  //           `slugged_name_${this.$i18n.locale}`
  //         ]
  //       }`
  //     ) {
  //       this.error({ statusCode: 404, message: '' })
  //     }
  //   } catch (e) {
  //     console.log('Error: $fetch with error: ', e)
  //   } finally {
  //     this.marketStore.loadingProducts = false
  //   }
  // },
  head() {
    const scripts = []
    const seoScript = this.getSeoFaq()
    const seoPopular = this.getSeoPopular()
    if (seoScript) scripts.push(seoScript)
    if (seoPopular) scripts.push(seoPopular)
    const title = `${this.marketStore.selfCategory?.data?.name} ${
      this.checkIsCategoryElektronika()
        ? this.$t('catalog_title_credit')
        : this.$t('catalog_title')
    }${
      this.marketStore.pagination.page > 1
        ? ' - ' + this.$t('page') + ' ' + this.marketStore.pagination.page
        : ''
    }`
    const description = `${this.marketStore.selfCategory?.data?.name} ${this.$t(
      'catalog_description',
    )} ${
      this.marketStore.pagination.page > 1
        ? '- ' + this.$t('page') + ' ' + this.marketStore.pagination.page
        : ''
    }`
    // if (this.marketStore.selfCategory?.data?.node_type === 'NODE') {
    //   title = this.marketStore.selfCategory?.data?.seo_title
    //     ? this.marketStore.selfCategory?.data?.seo_title
    //     : title
    //   description = this.marketStore.selfCategory?.data?.seo_description
    //     ? this.marketStore.selfCategory?.data?.seo_description
    //     : description
    // }

    const meta = [
      {
        hid: 'description',
        name: 'description',
        content: description,
      },
      {
        hid: 'og:title',
        name: 'og:title',
        content: `${this.marketStore.selfCategory?.data?.name} - ${this.$t(
          'product_title',
        )}${
          this.marketStore.pagination.page > 1
            ? ' - ' + this.$t('page') + ' ' + this.marketStore.pagination.page
            : ''
        } ${this.$t('domen_seo')}`,
      },
      {
        hid: 'og:description',
        name: 'og:description',
        content: `${this.marketStore.selfCategory?.data?.name} ${this.$t(
          'catalog_description',
        )} ${
          this.marketStore.pagination.page > 1
            ? '- ' + this.$t('page') + ' ' + this.marketStore.pagination.page
            : ''
        }`,
      },
      {
        hid: 'al:android:package',
        property: 'al:android:package',
        content: 'com.competo.umicoloy',
      },
      {
        hid: 'al:android:url',
        property: 'al:android:url',
        content: 'applinks://categories',
      },
      {
        hid: 'al:android:app_name',
        property: 'al:android:app_name',
        content: 'Android-Umico',
      },
      {
        hid: 'al:ios:url',
        property: 'al:ios:url',
        content: 'com.competo.umicoprod',
      },
      {
        hid: 'al:ios:app_store_id',
        property: 'al:ios:app_store_id',
        content: '1458111389',
      },
      {
        hid: 'al:ios:app_name',
        property: 'al:ios:app_name',
        content: 'Umico',
      },
      {
        hid: 'al:web:url',
        property: 'al:ios:app_name',
        content: 'https://umico.az/categories',
      },
    ]

    // Esli outlet to zapreshaem indeksaciyu
    if (+this.categoryId === 3890) {
      meta.push({
        hid: 'robots',
        name: 'robots',
        content: 'noindex, nofollow',
      })
    }

    return {
      title,
      meta,
      link: [
        {
          rel: 'alternate',
          href: this.$config.BASE_URL + this.$route.fullPath,
          hreflang: this.$i18n?.locale || '',
        },
        {
          rel: 'alternate',
          href: this.getAlternateHref(),
          hreflang: this.getHrefLang(),
        },
      ],
      script: scripts,
    }
  },
  computed: {
    ...mapWritableState(useCustomer, ['userProfile']),
    ...mapState(useMarketStore, ['breadcrumbs']),
    ...mapGetters({
      isAuthenticated: 'isAuthenticated',
    }),
    setSourceForCreditLine() {
      this.view_mode
      const source =
        this.view_mode === 'products_and_categories'
          ? 'catalog'
          : this.view_mode === 'categories'
          ? 'catalog'
          : 'item_list'
      return source
    },
  },
  watch: {
    productFilters(query) {
      const defaultQuery = { ...this.$route?.query }
      Object.keys(defaultQuery).forEach(key => {
        if (!SEARCH_QUERIES.includes(key)) {
          delete defaultQuery[key]
        }
      })
      this.$router
        .replace({ query: { ...defaultQuery, ...query } })
        .then(() => {
          this.viewItemList()
        })
        .catch(error => {
          if (error.name !== 'NavigationDuplicated') throw error
        })
    },
    $route: {
      handler(from, to) {
        initBanners(this.$config.ENVIRONMENT)
        // this.loadProducts()
        this.productFiltersFunc()
        if (from?.params?.slug !== to?.params?.slug) {
          this.marketStore.truncated = true
          this.marketStore.showPagination = 20
        }
      },
      deep: true,
    },
  },
  watchQuery: true,
  destroyed() {
    this.marketStore.truncated = true
    this.marketStore.showPagination = 20
    // this.marketStore.selfCategory = {}
    // this.marketStore.products = []
    // this.marketStore.localFilters = {}
    // this.marketStore.filters = {}
  },
  updated() {
    if (this.view_mode === 'categories') {
      this.marketStore.products = []
    }
    // if (['products_and_categories', 'products'].includes(this.view_mode)) {
    //   this.viewItemList()
    // }
  },
  async mounted() {
    const statuses = {
      'q[market_order]': true,
    }
    initBanners(this.$config.ENVIRONMENT, this.categoryId)
    this.marketStore.loadingProducts = false
    if (this.view_mode === 'categories') {
      this.marketStore.products = []
    }

    if (['products_and_categories', 'products'].includes(this.view_mode)) {
      this.viewItemList()
    }
    if (this.isAuthenticated) {
      if (!this.userProfile) {
        await this.getUser()
        const featureMGMStore = useFeatureMGMStore()
        await featureMGMStore.getPreScoringBanner()
      }
      await this.PromoCodeStore.GET_CUSTOMER_PACKAGE()
      await this.LotteryStore.GET_LOTTERY()
      if (!this.marketStore.minInstallmentAmount) {
        await this.marketStore.getMinInstallmentAmount()
      }
    }
    rtbAnalytics(this.$i18n.locale, {
      eventType: 'category',
      categoryId: `${this.categoryId}`,
    })
    // this._rtb(`_category2_${this.categoryId}`)

    this.bloomreachStore.viewCategory(this.marketStore.products)

    if (this.$route?.query.from_search) {
      this.source = 'search'
    }
    setViewSearchResultsSetup(
      this.$route,
      this.marketStore.pagination?.total_entries,
      this.$i18n.locale,
    )
  },
  methods: {
    ...mapActions(useCustomer, ['getUser']),
    getHrefLang() {
      return this.$i18n?.locale === 'az' ? 'ru' : 'az'
    },
    getAlternateHref() {
      const alternateLang = this.$i18n?.locale === 'az' ? 'ru' : 'az'
      const slug = this.marketStore.selfCategory?.slugged_name
      const prefix = alternateLang === 'ru' ? '/ru' : ''
      return (
        (slug &&
          `${this.$config.BASE_URL}${prefix}/categories/${this.marketStore.selfCategory?.id}-${slug}`) ||
        ''
      )
    },
    checkIsCategoryElektronika() {
      const parent = this.marketStore.selfCategory?.parent
      return (
        this.marketStore.selfCategory?.data?.id === 1 ||
        parent?.data?.id === 1 ||
        parent?.parent?.data?.id === 1 ||
        parent?.parent?.parent?.data?.id === 1
      )
    },
    // async loadProducts() {
    //   const uiStore = useUiStore()
    //   const { query } = this.$route
    //   const matches = this.$route.params.slug.match(/^([0-9]*)-(.*)/)
    //   const categoryId = matches && matches[1]
    //   const page = query?.page || 1
    //   if (process.browser) {
    //     this.marketStore.loadingProducts = true
    //   }
    //   const config = {
    //     page,
    //     filters: true,
    //     facets: this.view_mode === 'products',
    //   }
    //   let options = {
    //     page,
    //     category_id: categoryId,
    //     per_page: uiStore.fromBB ? 8 : PER_PAGE,
    //   }
    //   if (this.view_mode === 'products_and_categories') {
    //     if (query.sort) {
    //       options.sort = query.sort
    //     } else {
    //       options.sort = 'global_popular_score'
    //     }
    //   } else if (query.sort) {
    //     options.sort = query.sort
    //   } else {
    //     options.sort = 'category_popular_score'
    //   }
    //   const queryParams = query
    //   Object.keys(query).forEach(key => {
    //     queryParams[key] = Array.isArray(query?.[key])
    //       ? query?.[key]?.[0]
    //       : query?.[key]
    //   })
    //   options = {
    //     ...decodeQuery(filterQueryWhiteList(queryParams)),
    //     ...options,
    //   }
    //   try {
    //     await this.marketStore.getProducts({ options, config })
    //     this.marketStore.moreProductOptions = { options }
    //   } catch (e) {
    //     console.log('Error: loadProducts ', e)
    //   } finally {
    //     this.marketStore.loadingProducts = false
    //   }
    // },
  },
}
