import { mapGetters } from 'vuex'
import { mapState } from 'pinia'
import { useMarketStore } from '~/stores/market'

export default {
  setup() {
    const marketStore = useMarketStore()
    return { marketStore }
  },
  computed: {
    ...mapState(useMarketStore, ['seoFaqData']),
  },
  methods: {
    getQuestions(lang, page) {
      const now = new Date()
      const year = now.getFullYear()
      const category = this.marketStore.currentCategoryName
      const merchant = this.marketStore.merchantName
      const categoryQuestions = {
        ru: {
          cheap: `Какие ${category} более бюджетные?`,
          expensive: `Какие ${category} относятся к премиум сегменту?`,
          newest: `Какие новинки в категории ${category} в ${year} году?`,
          popular: `Какие ${category} популярны в ${year} году?`,
        },
        az: {
          cheap: `Hansı ${category} ${year} ildə populyardır?`,
          expensive: `${category} kategoriyasında ${year} ildə hansı yeniliklər var?`,
          newest: `Hansı ${category} sərfəlidirlər?`,
          popular: `Hansı ${category} premium seqmentinə aiddir?`,
        },
      }
      const merchantQuestions = {
        ru: {
          popular: `Какие товары ${merchant} наиболее популярны в ${year} году?`,
          newest: `Какие новинки у ${merchant} ?`,
          discount: `На какие товары ${merchant} действует максимальная скидка?`,
        },
        az: {
          popular: `${year} ərzində ən populyar ${merchant} məhsulları hansılardır? `,
          newest: `${merchant} hansı yeni məhsullara malikdir?`,
          discount: `${merchant} hansı məhsullarında maksimum endirim var?`,
        },
      }

      return page === 'merchant'
        ? merchantQuestions[lang]
        : categoryQuestions[lang]
    },

    getAnswer(lang, page, faqSectionName, faqSectionItems) {
      const len = faqSectionItems.length
      const category = this.marketStore.currentCategoryName
      const merchant = this.marketStore.merchantName
      const categoryAnswers = {
        ru: {
          cheap: `Недорогие товары из категории ${category} на Umico.az - `,
          expensive: `ТОП-${len} моделей премиум-класса в категории ${category} на Umico.az - `,
          newest: `${len} новинок из категории ${category} на Umico.az - `,
          popular: `ТОП-${len} популярных моделей в категории ${category} на Umico.az - `,
        },
        az: {
          cheap: `Umico.az-da ${category} kateqoriyasında TOP-${len} populyar məhsul - `,
          expensive: `Umico.az-da ${category} kateqoriyasında ${len} yaxşı yenilik - `,
          newest: `Umico.az-da ${category} kateqoriyasında ucuz məhsullar - `,
          popular: `Umico.az-da ${category} kateqoriyasında TOP-${len} premium modellər - `,
        },
      }
      const merchantAnswers = {
        ru: {
          popular: `ТОП-${len} самых востребованных товаров от ${merchant}:`,
          newest: `ТОП-${len} новинок от ${merchant} на Umico.az:`,
          discount: `ТОП-${len} товаров от ${merchant} с максимальной скидкой:`,
        },
        az: {
          popular: `${merchant}-dan TOP-${len} ən populyar məhsul:`,
          newest: `Umico.az saytında ${merchant}-dan TOP-${len} yeni məhsul:`,
          discount: `${merchant}-dan maksimum endirimlə TOP-${len} məhsul:`,
        },
      }
      const answers =
        page === 'merchant'
          ? merchantAnswers[lang][faqSectionName]
          : categoryAnswers[lang][faqSectionName]
      return answers + '\n' + this.getProductsString(lang, faqSectionItems)
    },

    getProductsString(lang, faqSectionItems) {
      const prefix = lang === 'az' ? '' : `/${lang}`
      const base_url = this.$config.BASE_URL ? this.$config.BASE_URL : ''
      return faqSectionItems
        .map(item => {
          const name = item?.name
          const href = `${base_url}${prefix}/product/${item.id}-${
            item.slugged_name + lang
          }`
          return `<a href=${href}>${name}</a>`
        })
        .join('<span>, ✓</span>')
    },

    getMainEntityFaq() {
      const lang = this.$i18n?.locale || 'az'
      const page = this.$route.name?.includes('merchant')
        ? 'merchant'
        : 'category'
      const questions = this.getQuestions(lang, page)
      if (!questions) return
      const mainEntity = []
      for (const faqSectionName in this.seoFaqData) {
        const faqItem = this.seoFaqData[faqSectionName]
        if (!faqItem || !Array.isArray(faqItem) || !faqItem.length) continue

        mainEntity.push({
          '@type': 'Question',
          name: questions[faqSectionName],
          acceptedAnswer: {
            '@type': 'Answer',
            text: this.getAnswer(
              lang,
              page,
              faqSectionName,
              this.seoFaqData[faqSectionName],
            ),
          },
        })
      }
      return mainEntity
    },

    getMainEntityHtml() {
      const lang = this.$i18n?.locale || 'az'
      const page = this.$route.name?.includes('merchant')
        ? 'merchant'
        : 'category'
      const questions = this.getQuestions(lang, page)
      if (!questions) return
      const mainEntity = []
      for (const faqSectionName in this.seoFaqData) {
        const faqItem = this.seoFaqData[faqSectionName]
        if (!faqItem || !Array.isArray(faqItem) || !faqItem.length) continue
        mainEntity.push({
          question: questions[faqSectionName],
          answer: this.getAnswer(
            lang,
            page,
            faqSectionName,
            this.seoFaqData[faqSectionName],
          ),
        })
      }
      return mainEntity
    },

    ldJson() {
      const mainEntity = this.getMainEntityFaq()

      if (!mainEntity || !Array.isArray(mainEntity) || !mainEntity.length)
        return null
      return {
        '@context': 'https://schema.org',
        '@type': 'FAQPage',
        mainEntity,
      }
    },

    getSeoFaq() {
      if (
        !this.seoFaqData ||
        !this.marketStore.currentCategoryName ||
        !this.marketStore.merchantName
      )
        return null
      const ldJson = this.ldJson()
      if (!ldJson) return null
      return {
        type: 'application/ld+json',
        json: ldJson,
      }
    },
  },
}
